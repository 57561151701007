<template>
  <div class="relation">
    <el-dialog
      :title="$locale.report_editor.relation.title.update"
      :visible.sync="form.status"
      :width="form.width">
      <span>
        <el-form ref="form" class="relation-form" size="mini" label-position="top" :model="form.attributes">
          <el-row :gutter="10">
            <el-col :span="12">
              <el-form-item>
                <el-scrollbar class="scroll-container">
                  <el-tree
                    ref="treeFrom"
                    :data="form.sourceFieldList.from"
                    node-key="id"
                    :default-checked-keys="form.attributes.fields.from"
                    @check-change="fromCheckNode"
                    show-checkbox>
                    <span class="custom-tree-node" slot-scope="{ node, data }">
                      <span>{{ node.label }}</span>
                      <span>{{ sortSelect(data, 'from') }}</span>
                    </span>
                  </el-tree>
                </el-scrollbar>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item>
                <el-scrollbar class="scroll-container">
                  <el-tree
                    ref="treeTo"
                    :data="form.sourceFieldList.to"
                    :default-checked-keys="form.attributes.fields.to"
                    node-key="id"
                    @check-change="toCheckNode"
                    show-checkbox>
                    <span class="custom-tree-node" slot-scope="{ node, data }">
                      <span>{{ node.label }}</span>
                      <span>{{ sortSelect(data, 'to') }}</span>
                    </span>
                  </el-tree>
                </el-scrollbar>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="form.status = false">{{ $locale.main.button.cancel }}</el-button>
        <el-button type="primary" @click="saveRelation">{{ $locale.main.button.apply }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'ConditionForm',

  props: ['value', 'fromFieldList', 'toFieldList'],

  watch: {
    value () {
      this.localValue = this.value
    }
  },

  data () {
    return {
      localValue: this.value,
      form: {
        status: false,
        attributes: {
          fields: {
            from: [],
            to: []
          }
        },
        sourceFieldList: {
          from: [],
          to: []
        },
        rules: {
        },
        width: '600px',
        checked: {
          from: [],
          to: []
        }
      }
    }
  },

  mounted () {
    this.form.sourceFieldList.from = this.fromFieldList
    this.form.sourceFieldList.to = this.toFieldList
  },

  methods: {
    sortSelect (data, listId) {
      let index = this.form.attributes.fields[listId].findIndex(item => item === data.id) + 1

      return !index ? '' : index
    },
    fromCheckNode (data, checked, indeterminate) {
      if (checked) {
        this.form.attributes.fields.from.push(data.id)
      } else {
        let index = this.form.attributes.fields.from.findIndex(item => item === data.id)

        this.form.attributes.fields.from.splice(index, 1)
      }

      this.lockedList('from', 'to', checked)
    },
    toCheckNode (data, checked, indeterminate) {
      if (checked) {
        this.form.attributes.fields.to.push(data.id)
      } else {
        let index = this.form.attributes.fields.to.findIndex(item => item === data.id)

        this.form.attributes.fields.to.splice(index, 1)
      }

      this.lockedList('to', 'from', checked)
    },
    lockedList (listFrom, listTo, locked = true) {
      if (locked) {
        if (this.form.sourceFieldList[listFrom].length === this.form.attributes.fields[listTo].length) {
          this.form.sourceFieldList[listTo].forEach((item) => {
            if (this.form.attributes.fields[listTo].indexOf(item.id) === -1) {
              item.disabled = true
            }
          })
        }

        if (this.form.sourceFieldList[listTo].length === this.form.attributes.fields[listFrom].length) {
          this.form.sourceFieldList[listFrom].forEach((item) => {
            if (this.form.attributes.fields[listFrom].indexOf(item.id) === -1) {
              item.disabled = true
            }
          })
        }
      } else {
        this.form.sourceFieldList[listFrom].forEach((item) => {
          item.disabled = false
        })
      }
    },
    open () {
      let fields = {
        from: [],
        to: []
      }
      if (typeof this.value !== 'undefined') {
        fields = this.value
      }
      this.form.status = true
      this.form.attributes = {
        fields: fields
      }
    },
    saveRelation () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.localValue = this.form.attributes.fields
          this.$emit('input', this.localValue)
          this.clearRelation()
        } else {
          console.log('Error submit!')
          return false
        }
      })
    },
    clearRelation () {
      this.form.sourceFieldList = {
        from: [],
        to: []
      }

      this.form.status = false
      this.form.attributes = {
        fields: {
          from: [],
          to: []
        }
      }
    }
  }
}
</script>

<style>
.relation .el-dialog__body {
  padding: 20px 20px;
  border-top: 1px solid #efefef;
  border-bottom: 1px solid #efefef;
}
.relation .el-dialog__footer {
  padding: 20px 20px 20px;
}
.relation-form.el-form--label-top .el-form-item__label {
  padding: 0 0 5px;
}
.relation-form .el-form-item--mini .el-form-item__label {
  line-height: 22px;
}
.relation .el-scrollbar__view {
  padding: 5px;
}
.relation .el-scrollbar {
  border: 1px solid #dcdfe6;
  border-radius: 5px;
}
.relation .scroll-container {
  height: 300px;
}
.relation .el-tree-node__content>.el-tree-node__expand-icon {
  display: none;
}
.relation .el-tree-node__content {
  margin-left: 5px;
  padding-right: 5px;
}
</style>
